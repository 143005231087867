<template>
    <v-container height="87vh">
        <ejs-chart :theme="theme" align="center" id="chartcontainer" :title="title"
            :primaryXAxis="primaryXAxis" :primaryYAxis="primaryYAxis" :axes="axes" :chartArea="chartArea"
            :width="width" :legendSettings="legendSettings" :tooltip="tooltip"
            @tooltipRender="customizeTooltip">
            <e-series-collection>
                <e-series :dataSource="dataChart" type="Column" xName="data" yName="disp_empresa" name="Fábrica"
                    width="2" opacity="1" fill="#121935">
                </e-series>
                <e-series v-for="(line, index) in processedDataChart" 
                        :key="index" 
                        :dataSource="line.dataPoints" 
                        type="Line" 
                        xName="data" 
                        yName="percentual_producao" 
                        :name="line.nome_recurso" 
                        width="2" 
                        opacity="1" 
                        :marker="marker">
                </e-series>
            </e-series-collection>
        </ejs-chart>
    </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import {
    ChartComponent,
    SeriesDirective,
    SeriesCollectionDirective,
    LineSeries,
    StackingColumnSeries,
    Tooltip,
    ColumnSeries,
    Category,
    Legend,
    Highlight,
    AnnotationsDirective,
    AnnotationDirective,
} from "@syncfusion/ej2-vue-charts";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";


let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Fluent2";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
    .replace(/-dark/i, "Dark")
    .replace(/contrast/i, "Contrast")
    .replace(/-highContrast/i, "HighContrast");

export default {
    name: "multiple_charts_desempenho_da_empresa",

    components: {
        "ejs-chart": ChartComponent,
        "ejs-dropdownlist": DropDownListComponent,
        "e-series-collection": SeriesCollectionDirective,
        "e-series": SeriesDirective,
        "e-annotations": AnnotationsDirective,
        "e-annotation": AnnotationDirective
    },

    props: {
        dataDesempenho: Object
    },

    data: function () {
        return {
            theme: theme,
            fields: { text: "name", value: "value" },

            primaryXAxis: {
                labelIntersectAction: "Rotate45",
                valueType: "Category",
                majorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
            },

            primaryYAxis: {
                minimum: 0,
                maximum: 100,
                interval: 5,
                lineStyle: { width: 0 },
                majorTickLines: { width: 0 },
                labelFormat: "{value}%",
            },
            chartArea: {
                border: {
                    width: 0,
                },
            },
            marker: {
                visible: true,
                width: 7,
                height: 7,
                circleMarker: { visible: true, width: 7, height: 7, isFilled: true },
                triangleMarker: { visible: true, width: 7, height: 7, isFilled: true },
                diamondMarker: { visible: true, width: 7, height: 7, isFilled: true },
                rectangleMarker: { visible: true, width: 7, height: 7, isFilled: true },
            },

            width: "100%",
            legendSettings: {
                visible: true,
                enableHighlight: true,
            },
            tooltip: {
                enable: true,
                format: "Disponibilidade: ${point.y}%<br>Meta: ${point.y}%", // Exemplo de formato dinâmico
            },

            title: "Desempenho da Empresa",
        };
    },
    watch: {
       
    },

    provide: {
        chart: [
            StackingColumnSeries,
            LineSeries,
            Category,
            ColumnSeries,
            Legend,
            Tooltip,
            Highlight,
        ],
    },
    methods: {
        customizeTooltip(args) {
            console.log(args);
            // Definindo o conteúdo do tooltip dinamicamente

            if (args.headerText != "META") {
                args.text = `Disponibilidade: ${args.data.pointY}%`;
            } else {
                args.text = `Meta: ${args.data.pointY}%`;
            }
        },
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
        dataChart() {
            return this.dataDesempenho
        },
        processedDataChart() {
            if (!this.dataDesempenho || !Array.isArray(this.dataDesempenho)) {
                return []; // Retorna um array vazio se `dataDesempenho` for nulo ou inválido
            }

            const groupedData = {};

            this.dataDesempenho.forEach((item) => {
                if (!groupedData[item.recurso]) {
                    groupedData[item.recurso] = {
                        nome_recurso: item.nome_recurso,
                        recurso: item.recurso,
                        dataPoints: []
                    };
                }
                groupedData[item.recurso].dataPoints.push({
                    data: item.data,
                    percentual_producao: item.percentual_producao,
                    disp_empresa: item.disp_empresa
                });
            });

            return Object.values(groupedData).map((group) => {
                return {
                    nome_recurso: group.nome_recurso,
                    recurso: group.recurso,
                    dataPoints: group.dataPoints
                };
            });
        }
    },
};
</script>
