import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_e_series = _resolveComponent("e-series")
  const _component_e_series_collection = _resolveComponent("e-series-collection")
  const _component_ejs_chart = _resolveComponent("ejs-chart")
  const _component_v_container = _resolveComponent("v-container")

  return (_openBlock(), _createBlock(_component_v_container, { height: "87vh" }, {
    default: _withCtx(() => [
      _createVNode(_component_ejs_chart, {
        theme: _ctx.theme,
        align: "center",
        id: "chartcontainer",
        title: _ctx.title,
        primaryXAxis: _ctx.primaryXAxis,
        primaryYAxis: _ctx.primaryYAxis,
        axes: _ctx.axes,
        chartArea: _ctx.chartArea,
        width: _ctx.width,
        legendSettings: _ctx.legendSettings,
        tooltip: _ctx.tooltip,
        onTooltipRender: $options.customizeTooltip
      }, {
        default: _withCtx(() => [
          _createVNode(_component_e_series_collection, null, {
            default: _withCtx(() => [
              _createVNode(_component_e_series, {
                dataSource: $options.dataChart,
                type: "Column",
                xName: "data",
                yName: "disp_empresa",
                name: "Fábrica",
                width: "2",
                opacity: "1",
                fill: "#121935"
              }, null, 8 /* PROPS */, ["dataSource"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.processedDataChart, (line, index) => {
                return (_openBlock(), _createBlock(_component_e_series, {
                  key: index,
                  dataSource: line.dataPoints,
                  type: "Line",
                  xName: "data",
                  yName: "percentual_producao",
                  name: line.nome_recurso,
                  width: "2",
                  opacity: "1",
                  marker: _ctx.marker
                }, null, 8 /* PROPS */, ["dataSource", "name", "marker"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["theme", "title", "primaryXAxis", "primaryYAxis", "axes", "chartArea", "width", "legendSettings", "tooltip", "onTooltipRender"])
    ]),
    _: 1 /* STABLE */
  }))
}